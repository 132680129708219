const funcNav = (() => {


  let nav = document.getElementById('jsNav');
  let navBtn = document.getElementById('jsNavBtn');
  
  navBtn.addEventListener('click', (event) => {
    event.preventDefault();
    event.stopPropagation();
    if( navBtn.classList.contains('act') == true ){
      nav.classList.remove('act');
      navBtn.classList.remove('act');
    }else{
      nav.classList.add('act');
      navBtn.classList.add('act');
    }
  });


  ScrollTrigger.create({
    trigger: 'body',
    start: '10px 0',
    // end: 'bottom center',
    toggleClass: { targets: nav, className: "act-bg" },
    // once: true
    // markers: true,
  });


})();
export default funcNav;