const funcUserAgent = (() => {

  let body = document.querySelector('body');
  let userAgent = window.navigator.userAgent.toLowerCase();
  // pc,tablet,sp
  if (
    navigator.userAgent.indexOf('iPhone') > 0 ||
    navigator.userAgent.indexOf('iPod') > 0 ||
    (navigator.userAgent.indexOf('Android') > 0 && navigator.userAgent.indexOf('Mobile') > 0)
  ) {
    body.classList.add('sp');
  } else if (
    navigator.userAgent.indexOf('iPad') > 0 ||
    navigator.userAgent.indexOf('Android') > 0 ||
    (navigator.userAgent.indexOf('Safari') > 0 && typeof document.ontouchstart !== 'undefined')
  ) {
    body.classList.add('tl');
  } else {
    body.classList.add('pc');
  }
  // OS
  if(userAgent.indexOf("windows nt") !== -1) {
    body.classList.add('Windows');
  } else if(userAgent.indexOf("android") !== -1) {
    body.classList.add('Android');
  } else if(userAgent.indexOf("iphone") !== -1 || userAgent.indexOf("ipad") !== -1) {
    body.classList.add('iOS');
  } else if(userAgent.indexOf("mac os x") !== -1) {
    body.classList.add('macOS');
  } else {
    body.classList.add('other_os');
  }
  // Browser
  if(userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) {
    body.classList.add('IE');
  } else if(userAgent.indexOf('edge') != -1) {
    body.classList.add('Edge');
  } else if(userAgent.indexOf('chrome') != -1) {
    body.classList.add('Chrome');
  } else if(userAgent.indexOf('safari') != -1) {
    body.classList.add('Safari');
  } else if(userAgent.indexOf('firefox') != -1) {
    body.classList.add('FireFox');
  } else if(userAgent.indexOf('opera') != -1) {
    body.classList.add('Opera');
  } else {
    body.classList.add('other_browser');
  }

})();
export default funcUserAgent;