const comRowtxt = (() => {


  window.addEventListener("load", generateRowText);
  // window.addEventListener("resize", generateRowText);

  function generateRowText() {
    const textContainers = document.querySelectorAll(".js-comRowtxt");
    const containerHeight = window.innerHeight;
    const lineWidth = 30;
    const text = "oyama city is a central city in the southern part of tochigi prefecture and boasts the second largest population in tochigi prefecture after utsunomiya city, with approximately 166,000 residents.oyama station is located at the gateway to the northern kanto region, connecting oyama, utsunomiya, and tokyo stations.oyama station was ranked second only to utsunomiya station in the ”tochigi prefecture popular stations ranking” released by real estate information website at home in 2022.the west exit of the station is scheduled for redevelopment (shiroyamacho 3-chome no. 2 urban area redevelopment project), and the area is expected to continue to grow in the future."; // 繰り返す文章を指定します。

    const repeatCount = Math.floor(containerHeight / lineWidth);
    let repeatedText = "";

    for (let i = 0; i < repeatCount; i++) {
      repeatedText += text + " ";
    }

    textContainers.forEach((container) => {
      container.innerHTML = repeatedText;
    });
    
  }




})();
export default comRowtxt;