const funcWindowResize = (() => {


  const resize = () =>{
    let sW = window.innerWidth;
    let sH = window.innerHeight;
    console.log(sW);
    console.log(sH);
    let main = document.querySelector('main');
    if (sW >= sH) {
      main.classList.add('w_base');
      main.classList.remove('h_base');
    } else {
      main.classList.add('h_base');
      main.classList.remove('w_base');
    }
    if( (sW*9) > (sH*16)){
      main.classList.add('v_w_base');
      main.classList.remove('v_h_base');
    }else{
      main.classList.add('v_h_base');
      main.classList.remove('v_w_base');
    }
  }
  resize()

  window.addEventListener("resize", (event) => {
    resize();
  });


})()
export default funcWindowResize;